import React from "react";
import {Helmet} from "react-helmet";


export default function Contact() {
    return (
        <div>
            <Helmet>
                <title>Contact</title>
            </Helmet>
            <div className="App-contact-title">
                <h6>Contact</h6>
            </div>
            <br/>
            <div className="App-contact-article">
                <p><b>Email: &nbsp;</b>kchengdusp@outlook.com</p>
                {/*<p><b>Academic: &nbsp;</b>chengkun@mit.edu</p>*/}
            </div>
        </div>
    );
}
