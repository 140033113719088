import React, { useEffect, useState } from "react";
import {Helmet} from "react-helmet";
import { Paper } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

import SomeSketch from "../assets/images/some_sketch.jpg";
import Questions from "../assets/images/feynman_question.jpg";
import Understand from "../assets/images/feynman_create_understand.jpg";
import JohnCarmack from "../assets/images/john_carmack.jpg";

const IMAGES = [
    SomeSketch,
    Questions,
    Understand,
    JohnCarmack,
]

export default function Sketch() {
    const [imgsLoaded, setImgsLoaded] = useState(false)

    useEffect(() => {
        const loadImage = image => {
            return new Promise((resolve, reject) => {
                const loadImg = new Image()
                loadImg.src = image
                // console.log(image)
                // wait 2 seconds to simulate loading time
                // loadImg.onload = () =>
                //     setTimeout(() => {
                //         resolve(image.url)
                //     }, 2000)

                loadImg.onload = () => {
                    // console.log(`Image loaded: ${image}`);
                    resolve(image);
                };
                loadImg.onerror = (err) => {
                    // console.error(`Failed to load image: ${image}`, err);
                    reject(err);
                };
            })
        }

        Promise.all(IMAGES.map(image => loadImage(image)))
            .then(() => {
                // console.log("All images loaded successfully");
                setImgsLoaded(true);
            })
            .catch(err => console.log("Failed to load images", err))
    })

    return (
        <div>
            <Helmet>
                <title>Sketch</title>
            </Helmet>
            <div className="App-sketch-title">
                <h6>Sketch</h6>
            </div>
            <br/>
            {imgsLoaded ? (
                    <Carousel
                        fullHeightHover={false} // Nav button only works within the button wrapper range.
                        // height={'518px'}
                        changeOnFirstRender={true}
                        // height={'518px'}
                        autoPlay={false}
                        IndicatorIcon={<div className={"App-sketch-circle"}/>}
                        indicatorIconButtonProps={{
                            style: {
                                margin: '3px',
                                width: '5px',
                                height: '5px',
                                borderRadius: '5px',
                                background: 'black',
                            }
                        }}
                        activeIndicatorIconButtonProps={{
                            style: {
                                margin: '3px',
                                width: '5px',
                                height: '5px',
                                borderRadius: '5px',
                                background: '#900C3F',
                            }
                        }}
                    >
                        {/*<h1>Loaded!</h1>*/}
                        <Paper>
                            <div>
                                <img src={IMAGES[0]} alt="mit scribbles" className={"App-sketch-image"}/>
                                <figcaption className={"App-sketch-figcation"}> Infinite Corridor, MIT, Feb 2020. Just
                                    before the outbreak of COVID-19 in the U.S. Photo taken by KC.
                                </figcaption>
                            </div>
                        </Paper>
                        <Paper>
                            <div>
                                <img src={IMAGES[1]} alt="feynman-questions-answers.png" className={"App-sketch-image"}/>
                                <figcaption className={"App-sketch-figcation"}>
                                    I would rather have questions that can't be answered
                                    than answers that can't be questioned. ——Richard Feynman.
                                </figcaption>
                            </div>
                        </Paper>
                        <Paper>
                            <div>
                                <img src={IMAGES[2]} alt="feynman-create-understand.png" className={"App-sketch-image"}/>
                                <figcaption className={"App-sketch-figcation"}>
                                    What I cannot create, I do not understand. ——Richard Feynman.
                                    <br/><br/>
                                </figcaption>
                            </div>
                        </Paper>
                        {/*<Paper>*/}
                        {/*    <div>*/}
                        {/*        <img src={IMAGES[3]} alt="john-carmack.png" className={"App-sketch-image"}/>*/}
                        {/*        <figcaption className={"App-sketch-figcation"}>*/}
                        {/*            You can't know everything, but you should convince yourself you can learn anything.*/}
                        {/*            ——John Carmack.*/}
                        {/*        </figcaption>*/}
                        {/*    </div>*/}
                        {/*</Paper>*/}
                    </Carousel>
            ) : (
                <i className={"App-research-article"}>Sketching images...</i>
            )}
        </div>
    );
}