import React from "react";
import {Box, Container, Grid} from "@mui/material";


class Footer extends React.Component {
    render() {
        return (
            <Box>
                <Container maxWidth={"xs"}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className={"App-footer"}>
                                <br/>
                                <p style={{
                                    margin: "0px",
                                }}
                                   className={"App-footer-copyright"}
                                >
                                    ©2024 K.C. All Rights Reserved.
                                </p>
                                <br/>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        )
    }
}

export default Footer;
