import React from "react";
import VanGogh from "../assets/images/van_gogh.gif";
import { Helmet } from "react-helmet";
import { Paper } from '@mui/material';

export default function Home() {
    return (
        <div className="App-home-title">
            <Helmet>
                <title>Home</title>
            </Helmet>
            <h6>Home</h6>
            <br/>
            <Paper>
                <figure>
                    <img src={VanGogh} alt="van gogh" className={"App-home-image"}/>
                    {/*<figcaption>&nbsp;Illustration by Brazilian Artist: Raissa Levati Pelegrim</figcaption>*/}
                    <figcaption>&nbsp;Gif by Artist joojaebum.</figcaption>
                </figure>
            </Paper>
        </div>
    )
}
